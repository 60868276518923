export const colorScheme = [
  '#D97706',
  '#FACC15',
  '#84CC16',
  '#06B6D4',
  '#0EA5E9',
  '#2563EB',
  '#A855F7',
  '#7C3AED',
  '#EC4899',
  '#E11D48',
  '#DC2626',
  '#FF0000',
];

export const colorSchemeWithOpacity = [
  '#fcd34d',
  '#fef08a',
  '#bef264',
  '#a5f3fc',
  '#a5f3fc',
  '#60a5fa',
  '#d8b4fe',
  '#a78bfa',
  '#f9a8d4',
  '#fb7185',
  '#ef4444',
  '#F37B71',
];

export const notesIconColors = [
  '#D70100', //red Icon
  '#10F500', //green Icon
  '#EC9603', //warning icon
];

export const secondaryColorScheme = [
  '#AAAAAA',
  '#FFFFFF',
  '#6E7E7D',
  '#0F172A',
  '#049BC6',
];
