import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from './api.service';
import { Observable, combineLatest, mergeMap, tap, withLatestFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TeamsService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService
  ) {}
  public countTeams(runId$: Observable<string>) {
    return runId$.pipe(
      mergeMap((runId) => {
        return this.apiService.countTeams(runId);
      })
    );
  }
  public getAllTeams$(runId$: Observable<string>) {
    return runId$.pipe(
      mergeMap((runId) => {
        return this.apiService.getTeamsWithIdAndName(runId);
      })
    );
  }

  getTeamListWithProfiles(runId$: Observable<string>) {
    return runId$.pipe(
      mergeMap((runId) => {
        return this.apiService.getAllTeams(runId);
      })
    );
  }
  getTeam$(teamId$: Observable<number>, runId$?: Observable<string>) {
    return teamId$.pipe(
      withLatestFrom(runId$),
      mergeMap(([teamId, runId]) => {
        return this.apiService.getTeamById(runId, teamId);
      })
    );
  }

  getNumberOfDirectories(
    teamId$: Observable<number>,
    runId$?: Observable<string>
  ) {
    return combineLatest([teamId$, runId$]).pipe(
      mergeMap(([teamId, runId]) => {
        return this.apiService.directoriesContributedBy(runId, { teamId });
      })
    );
  }
}
